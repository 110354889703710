export function setRecipeClosings(state, payload) {
  state.recipeClosings = payload.recipeClosings;
  state.recipeClosingsDate = payload.date;

}
export function startLoading(state) {
  state.loading = true
}
export function stopLoading(state) {
  state.loading = false
}

export function startExportLoading(state) {
  state.exportLoading = true
}

export function stopExportLoading(state) {
  state.exportLoading = false
}
