export function setRecipeClaims(state, payload) {

  let recipeClaims = []
  payload.hist.forEach(x => {
    recipeClaims.push({
      message: x.Comentario,
      date: x.FechaIngreso,
      type: 'request'
    })
  })
  payload.claims.forEach(x => {
    recipeClaims.push({
      message: x.MensajeRespuesta,
      date: `${x.FechaRespuesta} 14:00:00`,
      type: 'response'
    })
  })

  state.recipeClaims = recipeClaims;
}
export function setRecipeClaim(state, payload) {
  state.recipeClaims.push({
      message: payload.Comentario,
      date: payload.FechaIngreso,
      type: 'request'
  })
}
export function startLoading(state) {
  state.loading = true
}
export function stopLoading(state) {
  state.loading = false
}
