import { simpleGet, simplePost } from '../../services/genericRequests'

export async function getRecipeClaims({ commit }, payload) {
  commit('startLoading')
  const response = await simpleGet({ path: `claims/getRecipeClaims/${payload}` })
  commit('setRecipeClaims', response.data)
  commit('stopLoading')
  return response
}

export async function setRecipeClaim({ commit }, payload) {
  commit('startLoading')
  const response = await simplePost({ path: `claims/setRecipeClaim`, data: payload })

  if (response.data.message) {
    commit('stopLoading')
  } else if (response.data) {
    commit('setRecipeClaim', response.data)
    commit('stopLoading')
  }
  return response
}

