import { simpleGet, simplePost } from '../../services/genericRequests'

export async function getReceptions({ commit }, payload) {
  commit('startLoading')

  await simplePost({
    path: 'receptions/getReceptions',
    data: payload
  })
    .then(response => {
      if (response.data) {
        commit('setReceptions', response.data)
      }
      else
        alert('error on getReception')
    })
  commit('stopLoading')
}

export async function getReceptionsInfo({ commit }, payload) {
  commit('startLoading')
  await simplePost({
    path: 'receptions/info',
    data: payload
  }).then(response => {
    if (response.data) {
      commit('setReceptionsInfo', response.data)
    }
    else
      alert('error on receptionsinfo')
  })
  commit('stopLoading')
}

