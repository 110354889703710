import axios from 'axios'
import { isBefore } from 'date-fns'
import { storageGet } from '../../services/storage'
import { simpleGet } from '../../services/genericRequests'

import {
  parseSessionValues,
  parseUserData,
  getCommonHeaders,
  checkError
} from '../../services/utils'

export async function login({ commit, dispatch }, payload) {
  const pharmacyData = await axios
    .post('webUser/login', {
      username: payload.username,
      password: payload.password,
      remember_me: payload.remember_me
    }, getCommonHeaders())
    .then(async response => {
      commit('setSession', parseSessionValues(response.data))
      return dispatch('fetchUserData')
    }).catch(error => checkError(error))

  return pharmacyData
}

export async function fetchUserData({ commit }) {
  return await simpleGet({ path: 'webUser/user' }).then(response => {
    if(response.data)
    {
      commit('setUser', parseUserData(response.data))
      commit('pharmacy/setPharmacy', response.data.pharmacy, { root: true })
      return response.data
    }
    return false;
  })
}


export async function logout({ commit }) {
  const response = await simpleGet({ path: 'webUser/logout' })
  commit('cleanStorage')
  return response
}

export function getSession({ commit, dispatch }) {
  const tokenExpiration = new Date(storageGet('expiration_at'))

  if (tokenExpiration === null) {
    commit('cleanStorage')
  } else if (isBefore(tokenExpiration, new Date())) {
    commit('cleanStorage')
  } else {
    commit('loadStorageSession')
  }
}
