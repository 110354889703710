export default function () {
  return {
    accessToken: null,
    refreshToken: null,
    loginAt: null,
    expirationAt: null,
    user: null,
    userType: null,
  }
}
