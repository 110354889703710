import { simplePost } from "../../services/genericRequests";

export async function getDevolutions({ commit }, payload) {
  commit("startLoading");
  await simplePost({
    path: "devolutions",
    data: payload,
  }).then((response) => {
    if (response.data) {
      commit("setDevolutions", response.data);
    }
  });
  commit("stopLoading");
}

export async function saveDevolution({ commit }, payload) {
  commit("startLoading");
  return await simplePost({
    path: `devolutions/respond`,
    data: payload,
  }).then((response) => {
    if (response.data) {
      return response.data;
    }
  });
  commit("stopLoading");
}
