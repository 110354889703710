import { storageGet, storageSet, storageRemove } from '../../services/storage'

export function setSession (state, payload) {
  const {
    userType,
    accessToken,
    loginAt,
    expirationAt,
  } = payload

  state.accessToken = accessToken
  state.loginAt = loginAt
  state.expirationAt = expirationAt
  state.userType = userType

  storageSet('access_token', accessToken)
  storageSet('login_at', loginAt)
  storageSet('expiration_at', expirationAt)
  storageSet('user_type', userType)
}

export function setUser (state, payload) {
  state.user = payload
  storageSet('user', JSON.stringify(payload))
}

export function cleanStorage (state) {
  state.accessToken = null
  state.loginAt = null
  state.expirationAt = null
  state.user = null
  state.userType = null

  storageRemove('access_token')
  storageRemove('login_at')
  storageRemove('expiration_at')
  storageRemove('user')
  storageRemove('user_type')
  storageRemove('pharmacy')

}

export function loadStorageSession (state) {
  state.accessToken = storageGet('access_token')
  state.expirationAt = storageGet('expiration_at')
  state.loginAt = storageGet('login_at')
  state.userType = storageGet('user_type')
  state.user = JSON.parse(storageGet('user'))
}
