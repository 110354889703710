export function setReceptions(state, payload) {
  state.receptions = payload.items
  state.count = payload.count
}

export function setReceptionsInfo(state, payload) {
  state.iosperIncomes = payload.iosperIncomes
  state.receptionsProcessed = payload.receptionsProcessed
  state.receptionsErrors = payload.receptionsErrors
  state.newRecipes = payload.newRecipes
  state.pharmacyAssignments = payload.pharmacyAssignments
}

export function startLoading(state) {
  state.loading = true
}
export function stopLoading(state) {
  state.loading = false
}