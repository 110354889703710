import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import guardian from './guardian'

Vue.use(VueRouter)

const router = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes
})

router.beforeEach(guardian)

export default router
