/* eslint-disable no-use-before-define */
import isBefore from 'date-fns/isBefore'
import { storageGet } from '../services/storage'
import { checkUserType, status } from '../services/user'

export default async function (to, from, next) {


  if (to.matched.some(m => m.meta.noAuth)) {
    return next()
  }

  if (!checkLocalAuth()) {
    return next({ path: '/login' })
  }

  if (to.matched.some(m => m.meta.userType === 'admin' || m.meta.userType === 'iosper' || m.meta.userType === 'mesadeentrada' || m.meta.userType === 'technicaladministrator')) {
    await checkUserType(to.matched[0].meta.userType)
      .then(userTypeMatch => {
        if (userTypeMatch) { return next() }
        else return next({ path: 'error' })
      })
  }

  return next()
}

export const checkLocalAuth = () => {
  const authToken = storageGet('access_token')
  if (!authToken) {
    return false
  }

  const expireSession = new Date((storageGet('expiration_at')))
  if (!expireSession) return false

  return isBefore(Date.now(), expireSession)
}


