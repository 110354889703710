export function setDirectSale(state, payload) {
  if (payload === null || payload === undefined) {
    state.directSale = []
  } else {
    state.directSale = payload;
  }
}
export function startLoading(state) {
  state.loading = true
}
export function stopLoading(state) {
  state.loading = false
}

export function setOrders(state, payload) {
  if (payload === null || payload === undefined) {
    state.orders = []
  } else {
    state.orders = payload;
  }
}
export function startOrdersLoading(state) {
  state.ordersLoading = true
}
export function stopOrdersLoading(state) {
  state.ordersLoading = false
}

