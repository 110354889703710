import { simplePost } from "../../services/genericRequests";

export async function findHistorical({ commit }, payload) {
  commit("startLoading");
  const { data } = await simplePost({
    path: "recipes/historical",
    data: payload,
  });

  console.log(data.data);

  commit("setHistorical", data.data);
  commit("stopLoading");
}
