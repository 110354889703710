import axios from 'axios'
import { checkError, getAuthHeaders, getAuthHeadersForFile } from './utils'

export async function indexData(payload) {
  const data = await axios
    .get(
      payload.path,
      {
        ...getAuthHeaders(),
        params: payload.data
      }
    )
    .then(
      (response) => response
    )
  return data
}


export async function simpleGet(payload) {
  const data = await axios
    .get(payload.path, getAuthHeaders())
    .then((response) => {
      if (response.data) {
        return response
      }
      return false
    }).catch((e) => catchError(e))
  return data
}

export async function postFile(payload) {
  const data = await axios
    .post(
      payload.path,
      payload.data,
      getAuthHeadersForFile()
    )
    .then(response => response)
    .catch(error => checkError(error))
  return data
}

export async function simplePost(payload) {
  const data = await axios
    .post(
      payload.path,
      payload.data,
      getAuthHeaders()
    )
    .then(response => response)
    .catch(error => checkError(error))
  return data
}
