export async function setPharmacy ({ commit }, payload) {
  commit('startLoading')
  commit('setPharmacy', payload)
  commit('stopLoading')
}

export async function loadPharmacy ({ commit }) {
  commit('startLoading')
  commit('loadStoragePharmacy')
  commit('stopLoading')
}
