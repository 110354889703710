export default function () {
  return {
    receptions: [],
    iosperIncomes: null,
    receptionsProcessed: null,
    receptionsErrors: null,
    newRecipes: null,
    pharmacyAssignments: null,
    count: 0,
    loading: false,
  }
}
