
export function recipeClosings(state) {
  return state.recipeClosings
}

export function recipeClosingsDate(state) {
  return state.recipeClosingsDate
}

export function loading(state) {
  return state.loading
}

export function singleLoading(state) {
  return state.singleLoading
}

export function exportLoading(state) {
  return state.exportLoading
}
