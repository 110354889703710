
export function recipeClaims(state) {
  return state.recipeClaims
}

export function loading(state) {
  return state.loading
}

export function singleLoading(state) {
  return state.singleLoading
}
