import { formatISO, isPast } from 'date-fns'
import { storageGet } from './storage'
import { saveAs } from 'file-saver'
import { postFile } from '../services/genericRequests'

export function getCommonHeaders() {
  return {
    headers: {
      'content-type': 'application/json'
    }
  }
}


export function getAuthHeaders() {
  return {
    headers: {
      Authorization: storageGet('access_token'),
      'content-type': 'application/json',
    }
  }
}

export function getAuthHeadersForFile() {
  return {
    headers: {
      Authorization: storageGet('access_token'),
    },
    responseType: 'blob'
  }
}



export function parseSessionValues(data) {
  const loginAt = new Date()
  const expirationAt = new Date(data.expires_at)

  return {
    accessToken: `${data.token_type} ${data.access_token}`,
    loginAt: formatISO(loginAt),
    expirationAt: formatISO(expirationAt),
    userType: data.userType
  }
}

export function parseUserData(data) {
  return {
    username: data.username,
  }
}

export function parsePharmacyValues(data) {
  return {
    id: data.CodFarmacia,
    name: data.RazonSocial,
    address: data.Calle,
    zipCode: data.CodigoPostal
  }
}

export function checkError(error) {
  if (error.response.data) return error.response.data
  else return { message: 'Error interno' }
}

export function isNullOrEmpty(string) {
  if (string === null || string === '-' || string === '' || string === undefined) return true
  return false
}

export function paginateArray(arr, perPage) {
  var pages = [], i = 0, n = arr.length;
  while (i < n) {
    pages.push(arr.slice(i, i += perPage));
  }
  return pages;
}

export function downloadFile(file, name) {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
}

export async function downloadInstructive() {
  return await postFile({ path: 'downloadInstructive', data: {} })
    .then(response => {
      downloadFile(response.data, `Instructivo-Indosur.pdf`)
    })
}


export function dateIsBeforeNow(date) {
  return isPast(new Date(date))
}



