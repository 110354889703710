import { simpleGet, simplePost } from '../../services/genericRequests'

export async function getDirectSale({ commit }, payload) {
  commit('startLoading')
  const response = await simplePost({ path: 'sales/getDirectSaleList', data: payload })
  commit('setDirectSale', response.data.data)
  commit('stopLoading')
  return response
}

export async function getOrders({ commit }) {
  commit('startOrdersLoading')
  const response = await simpleGet({ path: 'sales/getOrders' })
  commit('setOrders', response.data.data)
  commit('stopOrdersLoading')
  return response;
}
