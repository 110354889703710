export default function () {
  return {
    invoices: [],
    count: 0,
    balance: null, 
    balanceDue: null,
    updatedAt: null,
    loading: false,
    saleInvoice: null,
    singleLoading: false
  }
}
