export function user (state) {
  return state.user
}

export function userType (state) {
  return state.userType
}

export function accessToken (state) {
  return state.accessToken
}
