import { simpleGet } from "./genericRequests";
import { clear } from "./storage";

export async function checkUserType(userType) {
  return await simpleGet({ path: "webUser/checkUserType/" + userType }).then(
    (x) => {
      if (x.data.userTypeMatch) return true;
      else {
        return false;
      }
    }
  );
}
