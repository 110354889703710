import { simplePost, simpleGet } from '../../services/genericRequests'

export async function getInvoices({ commit }, payload) {
  commit('startLoading')
  const response = await simplePost({ path: 'webUser/currentAccount/getInvoices', data: payload })
  if (response.data) {
    commit('setInvoices', response.data)
  } else {
    alert('Error interno')
  }
  commit('stopLoading')
  return response
}

export async function getBalances({ commit }) {
  commit('startLoading')
  const response = await simpleGet({ path: 'webUser/currentAccount' })
  commit('setBalances', response.data)
  commit('stopLoading')
  return response
}


export async function getSaleInvoiceDetail({ commit }, payload) {
  commit('startSingleLoading')
  commit('clearSaleInvoiceDetail')
  const response = await simpleGet({ path: 'webUser/currentAccount/detail/' + payload })
  commit('setSaleInvoice', response.data)
  commit('stopSingleLoading')
  return response
}
