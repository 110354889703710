export function setDevolutions(state, payload) {
  state.devolutions = payload.items
  state.count = payload.count
}

export function startLoading(state) {
  state.loading = true
}
export function stopLoading(state) {
  state.loading = false
}
