export default function () {
  return {
    recipes: [],
    recipesCount: 0,
    recipe: null,
    loading: false,
    singleLoading: false,
    recipeStatus: null,
    recipeStatusLoading: false,
    rowStatusLoading: false,
    recipeReturn: null,
    returnLoading: false,
    iosperMovements: []
  }
}
