const routes = [
  {
    path: "/",
    meta: { noAuth: true },
    component: () => import("../layout/PublicLayout.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: () => import("../views/public/Index.vue"),
      },
      {
        path: "us",
        name: "us",
        component: () => import("../views/public/Us.vue"),
      },
      {
        path: "login",
        name: "Login",
        component: () => import("../views/public/Login.vue"),
      },
    ],
  },
  {
    path: "/pharmacy",
    component: () => import("../layout/PharmacyLayout.vue"),
    children: [
      {
        path: "account",
        name: "Account",
        component: () => import("../views/pharmacy/Account.vue"),
      },
      {
        path: "closings",
        name: "Closings",
        component: () => import("../views/pharmacy/Closings.vue"),
      },
      {
        path: "sales",
        name: "Sales",
        component: () => import("../views/pharmacy/Sales.vue"),
      },
      {
        path: "recipes",
        name: "Recipes",
        component: () => import("../views/pharmacy/Recipes.vue"),
      },
    ],
  },
  {
    path: "/admin",
    meta: { userType: "admin" },
    component: () => import("../layout/AdminLayout.vue"),
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("../views/admin/Dashboard.vue"),
      },
      {
        path: "receptions",
        name: "Receptions",
        component: () => import("../views/admin/Receptions.vue"),
      },
    ],
  },
  {
    path: "/iosper",
    meta: { userType: "iosper" },
    component: () => import("../layout/IosperLayout.vue"),
    children: [
      {
        path: "recipes",
        name: "Recipes",
        component: () => import("../views/iosper/Recipes.vue"),
      },
    ],
  },
  {
    path: "/technical-administration",
    meta: { userType: "technicaladministration" },
    component: () => import("../layout/AdministracionTecnicaLayout.vue"),
    children: [
      {
        path: "recipes",
        name: "Recipes",
        component: () => import("../views/iosper/Recipes.vue"),
      },
      {
        path: "historical",
        name: "Historical",
        component: () =>
          import("../views/technical-administration/Historical.vue"),
      },
    ],
  },
  {
    path: "/mesadeentrada",
    meta: { userType: "mesadeentrada" },
    component: () => import("../layout/MesaDeEntradaLayout.vue"),
    children: [
      {
        path: "devolutions",
        name: "Devoluciones",
        component: () => import("../views/mesadeentrada/Devolutions.vue"),
      },
    ],
  },
  {
    path: "*",
    component: () => import("../views/public/Error404.vue"),
  },
];

export default routes;
