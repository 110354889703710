import { storageSet, storageGet, storageRemove } from '../../services/storage'
import { simplePost } from '../../services/genericRequests.js'

export function addToCart({ commit }, payload) {
  commit('addToCart', payload)
  addToStorage(payload)
}

export function removeItemFromCart({ commit }, payload) {
  commit('removeFromCart', payload)
  removeFromStorage(payload)
}

export async function saveOrder({ commit }, payload) {
  commit('startSavingOrder')
  const response = await simplePost({
    path: 'sales/saveOrder',
    data: {
      items: payload
    }
  })
  commit('stopSavingOrder')
  return response.data;

}

export function cleanShoppingCart({ commit }) {
  commit('cleanShoppingCart')
  storageRemove('shopping_cart')
}


export function addToStorage(payload) {
  let storedShoppingCart = JSON.parse(
    storageGet('shopping_cart')
  ) || []
  if (storedShoppingCart
    .map(x => x.id)
    .indexOf(payload.id) === -1) {
    storedShoppingCart.push({
      ...payload,
      amount: 1
    })
  }
  storageSet(
    'shopping_cart',
    JSON.stringify(storedShoppingCart)
  )
}

export function removeFromStorage(payload) {
  let storedShoppingCart = JSON.parse(
    storageGet('shopping_cart')
  ) || []
  const index = storedShoppingCart
    .map(x => x.id)
    .indexOf(payload.id)
  if (index !== -1) {
    storedShoppingCart.splice(index, 1)
    storageSet(
      'shopping_cart',
      JSON.stringify(storedShoppingCart)
    )
  }
}

export function loadStoredShoppingCart({ commit }) {
  const shoppingCart = JSON.parse(storageGet('shopping_cart')) || [];
  commit('setStoredShoppingCart', shoppingCart);
}

