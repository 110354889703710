import { postFile, simplePost } from '../../services/genericRequests'
import { downloadFile } from '../../services/utils'

export async function getRecipeClosings({ commit }, payload) {
  commit('startLoading')
  const response = await simplePost({ path: 'recipes/getRecipeClosings', data: payload })
  if (response.status !== 500) {
    commit('setRecipeClosings', response.data)
  }
  commit('stopLoading')
  return response
}

export async function exportRecipeClosings({ commit }, payload) {
  commit('startExportLoading')
  await postFile({ path: 'recipes/exportRecipeClosings', data: payload.data })
    .then(response => {
      downloadFile(response.data, `Cierres-${payload.date}.xlsx`)
    })
  commit('stopExportLoading')
}

