export function addToCart(state, payload) {
  console.log(payload)
  const index = state.shoppingCart
    .map(x => x.id)
    .indexOf(payload.id)
  if (index != -1) {
    state.shoppingCart[index].amount += 1
  } else {
    state.shoppingCart.push({
      ...payload,
      amount: 1
    })
  }
}

export function removeFromCart(state, payload) {
  const index = state.shoppingCart
    .map(x => x.id)
    .indexOf(payload.id)
  if (index != -1) {
    state.shoppingCart.splice(index, 1)
  }
}

export function cleanShoppingCart(state) {
  state.shoppingCart = [];
}

export function setStoredShoppingCart(state, payload) {
  state.shoppingCart = payload
}

export function startSavingOrder(state) {
  state.loading = true
}


export function stopSavingOrder(state) {
  state.loading = false
}
