export function receptions(state) {
  return state.receptions
}


export function iosperIncomes(state) {
  return state.iosperIncomes
}

export function receptionsProcessed(state) {
  return state.receptionsProcessed
}

export function receptionsErrors(state) {
  return state.receptionsErrors
}

export function newRecipes(state) {
  return state.newRecipes
}

export function pharmacyAssignments(state) {
  return state.pharmacyAssignments
}


export function count(state) {
  return state.count
}
export function loading(state) {
  return state.loading
}
