export function invoices (state) {
  return state.invoices
}
export function count (state) {
  return state.count
}
export function balance (state) {
  return state.balance
}
export function balanceDue (state) {
  return state.balanceDue
}
export function updatedAt (state) {
  return state.updatedAt
}
export function loading (state) {
  return state.loading
}
export function saleInvoice (state) {
  return state.saleInvoice
}
export function singleLoading (state) {
  return state.singleLoading
}



