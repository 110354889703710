export function setInvoices(state, payload) {
  if (payload !== null && payload.invoices && payload.count) {
    state.invoices = payload.invoices
    state.count = payload.count
  } else {
    state.invoices = []
    state.count = 0
  }
}

export function setDueInvoices(state, payload) {
  if (payload !== null && payload.dueInvoices && payload.count) {
    state.dueInvoices = payload.dueInvoices
    state.dueInvoicesCount= payload.count
  } else {
    state.dueInvoices = []
    state.count = 0
  }
}


export function setBalances(state, payload) {
  state.balance = payload.balance
  state.balanceDue = payload.balanceDue
  state.updatedAt = payload.updatedAt
}

export function startLoading(state) {
  state.loading = true;
}
export function stopLoading(state) {
  state.loading = false;
}
export function startDueInvoicesLoading(state) {
  state.dueInvoicesloading = true;
}
export function stopDueInvoicesLoading(state) {
  state.dueInvoicesloading = false;
}
export function clearSaleInvoiceDetail(state) {
  state.saleInvoice = null;
}
export function setSaleInvoice(state, payload) {
  state.saleInvoice = payload
}
export function startSingleLoading(state) {
  state.singleLoading = true;
}
export function stopSingleLoading(state) {
  state.singleLoading = false;
}


