export function setRecipes(state, payload) {
  if (payload === null || payload === undefined) {
    state.recipes = []
  } else {
    state.recipes = payload.recipes;
    state.recipesCount = payload.count;
  }
}
export function startLoading(state) {
  state.loading = true
}
export function stopLoading(state) {
  state.loading = false
}

export function setRecipe(state, payload) {
  state.recipe = payload
}
export function startSingleLoading(state) {
  state.singleLoading = true
}
export function stopSingleLoading(state) {
  state.singleLoading = false
}
export function clearRecipeState(state) {
  state.recipe = null;
}

export function setRecipeStatus(state, payload) {
  state.recipeStatus = payload.data
}
export function startRecipeStatusLoading(state) {
  state.recipeStatusLoading = true
}
export function stopRecipeStatusLoading(state) {
  state.recipeStatusLoading = false
}
export function clearRecipeStatus(state) {
  state.recipeStatus = null
}

export function setRowReception(state, payload) {
  const rowToUpdate = state.recipeStatus.delivery.dates.map(x => { return x.id }).indexOf(payload.id);
  state.recipeStatus.delivery.dates[rowToUpdate].receptationDate = payload.receptationDate;
}

export function setRowDispensation(state, payload) {
  const rowToUpdate = state.recipeStatus.delivery.dates.map(x => { return x.id }).indexOf(payload.id);
  state.recipeStatus.delivery.dates[rowToUpdate].dispensationDate = payload.dispensationDate;
}

export function startReturnLoading(state) {
  state.returnLading = true
}
export function stopReturnLoading(state) {
  state.returnLoading = false
}


export function setRecipeReturn(state, payload) {
  state.recipeReturn = payload
}

//iosper

export function setIosperMovements(state, payload) {
  if (payload)
    state.iosperMovements = payload;
  else
    state.iosperMovements = [];
}