import { storageGet, storageSet, storageRemove } from '../../services/storage'

export function setPharmacy (state, payload) {
  const pharmacy = payload
  state.pharmacy = pharmacy
  storageSet('pharmacy', JSON.stringify(pharmacy))
}

export function loadStoragePharmacy (state) {
  state.pharmacy = JSON.parse(storageGet('pharmacy'))
}

export function cleanStorage (state) {
  state.pharmacy = null
  storageRemove('pharmacy')
}

export function startLoading (state) {
  state.loading = true
}
export function stopLoading (state) {
  state.loading = false
}
