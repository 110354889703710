import Vue from 'vue'
import Vuex from 'vuex'

// Pharmacy
import claims from './claims'
import closings from './closings'
import currentAccount from './currentAccount'
import devolutions from './devolutions'
import directSale from './directSale'
import historical from './historical'
import pharmacy from './pharmacy'
import recipes from './recipes'
import session from './session'
import shoppingCart from './shoppingCart'

// Admin

import receptions from './receptions'

Vue.use(Vuex)

export default function () {
  const Store = new Vuex.Store({
    modules: {
      claims,
      closings,
      currentAccount,
      directSale,
      recipes,
      pharmacy,
      receptions,
      session,
      shoppingCart,
      devolutions,
      historical
    },

    // for dev mode only
    strict: process.env.DEV
  })

  return Store
}
