export function directSale(state) {
  return state.directSale
}
export function loading(state) {
  return state.loading
}

export function orders(state) {
  return state.orders
}
export function ordersLoading(state) {
  return state.ordersLoading
}
