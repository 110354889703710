export function recipes(state) {
  return state.recipes
}
export function recipesCount(state) {
  return state.recipesCount
}

export function recipe(state) {
  return state.recipe
}

export function loading(state) {
  return state.loading
}

export function singleLoading(state) {
  return state.singleLoading
}

export function recipeStatus(state) {
  return state.recipeStatus
}

export function recipeStatusLoading(state) {
  return state.recipeStatusLoading
}

export function rowReceptionLoading(state) {
  return state.rowReceptionLoading;
}

export function returnLoading(state) {
  return state.returnLoading
}

export function recipeReturn(state) {
  return state.recipeReturn
}
//iosper
export function iosperMovements (state) {
  return state.iosperMovements
}

